import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardHeader = _resolveComponent("ForwardHeader")!
  const _component_EducationHero = _resolveComponent("EducationHero")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_EducationBlock = _resolveComponent("EducationBlock")!
  const _component_EducationBlockBKF = _resolveComponent("EducationBlockBKF")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_overlay = _resolveComponent("b-overlay")!
  const _component_EducationPriceBlock = _resolveComponent("EducationPriceBlock")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_ForwardHeader)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            xl: "3",
            class: "order-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EducationHero)
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            xl: "6",
            class: "order-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_overlay, {
                id: "overlay-background",
                show: _ctx.loading,
                rounded: "sm",
                class: "w-100 h-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_row, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.examAndEducationList, (examAndEducation) => {
                        return (_openBlock(), _createBlock(_component_b_col, {
                          xl: "6",
                          class: "mt-4 mt-xl-0",
                          key: examAndEducation.id
                        }, {
                          default: _withCtx(() => [
                            (!examAndEducation.bkfEducation)
                              ? (_openBlock(), _createBlock(_component_EducationBlock, {
                                  key: 0,
                                  "student-exam-and-education": examAndEducation
                                }, null, 8, ["student-exam-and-education"]))
                              : _createCommentVNode("", true),
                            (examAndEducation.bkfEducation)
                              ? (_openBlock(), _createBlock(_component_EducationBlockBKF, {
                                  key: 1,
                                  "student-exam-and-education": examAndEducation
                                }, null, 8, ["student-exam-and-education"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["show"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            xl: "3",
            class: "order-2 mt-4 mt-xl-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EducationPriceBlock, {
                pricesByStudent: _ctx.prices,
                loading: _ctx.pricesLoading
              }, null, 8, ["pricesByStudent", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}